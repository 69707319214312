<template>
    <div class="inner-section">
        <bread-cumb />
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('sideBar.profile') }} {{ $t('globalTrans.update') }}</h4>
            </template>
            <!-- <template v-slot:headerAction>
        <b-link class="btn-add" to="/external-user/profile-update">
            <i class="ri-pencil-fill"></i>{{ $t('globalTrans.update_profile') }}
        </b-link>
      </template> -->
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <b-row>
                        <b-col md="3" class="text-center align-top iq-border-r-5 pt-3">
                            <img v-if="commonProfile" class="img-thumbnail w-50" style="height: 130px;"
                                :src="baseUrl + 'storage' + academicInfo.image" alt="Profile Image">
                            <img v-else class="img-thumbnail w-50" style="height: 130px" :src="preview" alt="Image">
                        </b-col>
                        <b-col v-if="commonProfile" md="9">
                            <table class="table table-sm table-borderless">
                                <tr>
                                    <th width="15%">{{$t('globalTrans.name')}}</th>
                                    <td width="35%">: {{ currentLocale === 'bn' ? authUser.name_bn : authUser.name }}</td>
                                    <th width="15%">{{$t('globalTrans.email')}}</th>
                                    <td width="35%">: {{ authUser.email }}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('globalTrans.mobile')}}</th>
                                    <td>: {{currentLocale == 'bn'? authUser.mobile.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d]) :
                                        authUser.mobile}}</td>
                                    <th>{{ $t('globalTrans.nid') }}</th>
                                    <td>: {{ $n(profile.nid_no, { useGrouping: false }) }}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('globalTrans.father_name')}}</th>
                                    <td>: {{ currentLocale === 'bn' ? profile.father_name_bn : profile.father_name_en }}
                                    </td>
                                    <th>{{$t('globalTrans.mother_name')}}</th>
                                    <td>: {{ currentLocale === 'bn' ? profile.mother_name_bn : profile.mother_name_en }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{{$t('globalTrans.gender')}}</th>
                                    <td>: {{ profile.gender ? getGenderName(profile.gender) : '' }}</td>
                                    <th>{{$t('globalTrans.dob')}}</th>
                                    <td>: {{ profile.dob | dateFormat }}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('externalUser.birth_place')}}</th>
                                    <td>: {{ currentLocale === 'bn' ? (profile.birth_place_bn ? profile.birth_place_bn : 'N/A' ) : (profile.birth_place_en ? profile.birth_place_en : 'N/A' ) }}
                                    </td>
                                    <th>{{$t('globalTrans.nationality')}}</th>
                                    <td>: {{ currentLocale === 'bn' ? (profile.nationality_bn ? profile.nationality_bn : 'N/A') : (profile.nationality_en ? profile.nationality_en : 'N/A') }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{{$t('globalTrans.occupation')}}</th>
                                    <td colspan="3">: {{ currentLocale === 'bn' ? (profile.occupation_bn ? profile.occupation_bn : 'N/A') :
                                        (profile.occupation_en ? profile.occupation_en : 'N/A') }}</td>
                                </tr>
                            </table>
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <p class="text-black" style="border-bottom: 1px solid #ddd; font-weight: bold">
                                        {{ $t('externalTraining.professional_info') + ' :' }}
                                    </p>
                                </b-col>
                                <b-col lg="12" sm="12">
                                    <table class="table table-sm table-borderless">
                                        <tr>
                                            <th width="20%">{{$t('elearning_config.organization_type')}}</th>
                                            <td width="35%">: {{ getOrg(professionalInfo.org_type_id) }}
                                            </td>
                                            <th>{{$t('elearning_config.office_type')}}</th>
                                            <td>: {{ getOfficeType(professionalInfo.office_type_id)  }}</td>
                                        </tr>
                                        <tr>
                                            <th width="20%">{{$t('globalTrans.org_name')}}</th>
                                            <td width="35%">: {{ currentLocale === 'bn' ? professionalInfo.org_name_bn : professionalInfo.org_name_en }}
                                            </td>
                                            <th>{{$t('globalTrans.office')}}</th>
                                            <td>: {{ getOfficeName(professionalInfo.office_id)
                                                }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('elearning_iabm.job_type')}}</th>
                                            <td>: {{ getJobTypes(professionalInfo.job_type_id) }}</td>
                                            <th>{{$t('elearning_iabm.batch')}}</th>
                                            <td>: {{ professionalInfo.batch ? professionalInfo.batch :
                                                'N/A' }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('externalTraining.designation')}}</th>
                                            <td>: {{ currentLocale === 'bn' ? professionalInfo.designation_bn : professionalInfo.designation_en }}</td>
                                            <th>{{$t('externalTraining.pay_grade')}}</th>
                                            <td>: {{ professionalInfo.grade_id ? professionalInfo.grade_id :
                                                    'N/A' }}</td>
                                        </tr>
                                        <tr>
                                            <th width="22%">{{$t('externalTraining.designation_c')}}</th>
                                            <td>: {{ professionalInfo.controlling_authority ? professionalInfo.controlling_authority :
                                                'N/A' }}</td>
                                            <th width="22%">{{$t('externalTraining.telephone_c')}}</th>
                                            <td>: {{ $n(professionalInfo.telephone) }}</td>
                                        </tr>
                                        <tr>
                                            <th width="22%">{{$t('externalTraining.email_c')}}</th>
                                            <td>: {{ professionalInfo.email ? professionalInfo.email :
                                                'N/A' }}</td>
                                            <th>{{$t('externalTraining.office_mail')}}</th>
                                            <td>: {{ professionalInfo.office_mail_address ? professionalInfo.office_mail_address :
                                                    'N/A' }}</td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                            <b-row v-if="academicInfo.name_of_highest_degree && academicInfo.board">
                                <b-col lg="12" sm="12">
                                    <p class="text-black" style="border-bottom: 1px solid #ddd; font-weight: bold">
                                        {{ $t('externalTraining.academic_info') + ' :' }}
                                    </p>
                                </b-col>
                                <b-col lg="12" sm="12">
                                    <table class="table table-sm table-borderless">
                                        <tr>
                                            <th width="25%">{{$t('externalTraining.highest_degree')}}</th>
                                            <td width="35%">: {{ academicInfo.name_of_highest_degree }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th width="15%">{{$t('externalTraining.board_university')}}</th>
                                            <td width="35%">: {{ academicInfo.board }}
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <p class="text-black" style="border-bottom: 1px solid #ddd; font-weight: bold">
                                        {{ $t('globalTrans.present_address') + ' :' }}
                                    </p>
                                </b-col>
                                <b-col lg="12" sm="12">
                                    <table class="table table-sm table-borderless">
                                        <tr>
                                            <th width="15%">{{$t('globalTrans.division')}}</th>
                                            <td width="35%">: {{ AddressHelper.getDivisionName(profile.pr_division_id) }}
                                            </td>
                                            <th width="15%">{{$t('globalTrans.district')}}</th>
                                            <td width="35%">: {{ AddressHelper.getDistrictName(profile.pr_district_id) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('globalTrans.upazila')}}</th>
                                            <td>: {{ profile.pr_upazila_id ?
                                                AddressHelper.getUpazilaName(profile.pr_upazila_id) : 'N/A' }}</td>
                                            <th>{{$t('globalTrans.city_corporation')}}</th>
                                            <td>: {{ profile.pr_city_corporation_id ?
                                                AddressHelper.getCityCorporationName(profile.pr_city_corporation_id) : 'N/A'
                                                }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('globalTrans.pouroshova')}}</th>
                                            <td>: {{ profile.pr_pauroshoba_id ?
                                                AddressHelper.getPauroshobaName(profile.pr_pauroshoba_id) : 'N/A' }}</td>
                                            <th>{{$t('globalTrans.union')}}</th>
                                            <td>: {{ profile.pr_union_id ? AddressHelper.getUnionName(profile.pr_union_id) :
                                                'N/A' }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('globalTrans.ward')}}</th>
                                            <td>: {{ profile.pr_ward_id ? AddressHelper.getWardName(profile.pr_ward_id) :
                                                'N/A' }}</td>
                                            <th>{{$t('externalUser.address_details')}}</th>
                                            <td>: {{ currentLocale === 'bn' ? (profile.pr_address_details_bn ? profile.pr_address_details_bn : 'N/A') :
                                                    (profile.pr_address_details_en ? profile.pr_address_details_en : 'N/A') }}</td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <p class="text-black" style="border-bottom: 1px solid #ddd; font-weight: bold">
                                        {{ $t('globalTrans.permanent_address') + ' :' }}
                                    </p>
                                </b-col>
                                <b-col lg="12" sm="12">
                                    <table class="table table-sm table-borderless">
                                        <tr>
                                            <th width="15%">{{$t('globalTrans.division')}}</th>
                                            <td width="35%">: {{ AddressHelper.getDivisionName(profile.pm_division_id) }}
                                            </td>
                                            <th width="15%">{{$t('globalTrans.district')}}</th>
                                            <td width="35%">: {{ AddressHelper.getDistrictName(profile.pm_district_id) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('globalTrans.upazila')}}</th>
                                            <td>: {{ profile.pm_upazila_id ?
                                                AddressHelper.getUpazilaName(profile.pm_upazila_id) : 'N/A' }}</td>
                                            <th>{{$t('globalTrans.city_corporation')}}</th>
                                            <td>: {{ profile.pm_city_corporation_id ?
                                                AddressHelper.getCityCorporationName(profile.pm_city_corporation_id) : 'N/A'
                                                }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('globalTrans.pouroshova')}}</th>
                                            <td>: {{ profile.pm_pauroshoba_id ?
                                                AddressHelper.getPauroshobaName(profile.pm_pauroshoba_id) : 'N/A' }}</td>
                                            <th>{{$t('globalTrans.union')}}</th>
                                            <td>: {{ profile.pm_union_id ? AddressHelper.getUnionName(profile.pm_union_id) :
                                                'N/A' }}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('globalTrans.ward')}}</th>
                                            <td>: {{ profile.pm_ward_id ? AddressHelper.getWardName(profile.pm_ward_id) :
                                                'N/A' }}</td>
                                            <th>{{$t('externalUser.address_details')}}</th>
                                            <td>: {{ currentLocale === 'bn' ? (profile.pm_address_details_bn ? profile.pm_address_details_bn : 'N/A') :
                                                (profile.pm_address_details_en ? profile.pm_address_details_en : 'N/A') }}</td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
    </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import BreadCumb from '../../../../../../components/BreadCumb.vue'
import NoImage from '../../../../../../assets/images/user/no-image.jpg'
import AddressHelper from '../../../../../../utils/area-type-address'
import { mapGetters } from 'vuex'
export default {
    components: { BreadCumb },
    name: 'ProfileShow',
    data () {
        return {
            loader: false,
            baseUrl: trainingElearningServiceBaseUrl,
            preview: NoImage,
            isLoaded: false,
            AddressHelper: AddressHelper,
            profile: {},
            professionalInfo: [],
            academicInfo: [],
            jobTypeList: []
        }
    },
    created () {
        if (this.authUser && this.commonProfile) {
            this.profile = this.commonProfile
        } else {
            this.$router.push('/external-user/profile-update')
        }
        RestApi.getData(trainingElearningServiceBaseUrl, '/personal-infos/profile-info').then(response => {
            if (response.success) {
                this.loader = true
                this.academicInfo = response.data
                this.professionalInfo = response.data.professional_info
            }
            this.loader = false
        })
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        ...mapGetters({
            authUser: 'Auth/authUser',
            commonProfile: 'Auth/commonProfile'
        }),
        officeTypeList () {
            return this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
        }
    },
    watch: {
    },
    methods: {
        getGenderName (val) {
            const Obj = this.$store.state.commonObj.genderList.find(el => el.value === val)
            return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
        },
        getJobTypes (val) {
            if (val) {
                const Obj = this.$store.state.ExternalUserService.trainingPanel.commonObj.jobTypeList.find(item => parseInt(item.value) === parseInt(val))
                if (Obj !== undefined) {
                    return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
                }
            }
        },
        getOrg (sta) {
            if (sta === 1) {
                return this.$i18n.locale === 'en' ? 'MOC' : 'এমওসি'
            } else if (sta === 2) {
                return this.$i18n.locale === 'en' ? 'Private' : 'ব্যক্তিগত'
            } else if (sta === 3) {
                return this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য'
            }
        },
        getOfficeType (officeTypeId) {
            const cateObj = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1).find(item => item.value === officeTypeId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        },
        getOfficeName (officeTypeId) {
            const cateObj = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1).find(item => item.value === officeTypeId)
            if (cateObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return cateObj.text_bn
                } else {
                    return cateObj.text_en
                }
            }
        }
    }
}
</script>
<style scope='css'>.blink_me {
    animation: blinker 1.3s linear infinite;
    color: red;
}

@keyframes blinker {
    50% {
        opacity: .09;
        /* background-color: green; */
    }
}</style>
